const deviceTypeList = [
  {
    label: 'Yolobox',
    value: '1'
  },
  {
    label: 'Yolobox Pro',
    value: '2'
  },
  {
    label: 'Yolobox Mini',
    value: '3'
  },
  {
    label: 'Instream',
    value: '4'
  },
  {
    label: 'YoloMax',
    value: '5'
  },
  {
    label: 'Yolobox Ultra',
    value: '6'
  },
  {
    label: 'Yolobox Extreme',
    value: '7'
  }
]

export default deviceTypeList
