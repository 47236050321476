const publishVersionRecordApis = [
  {
    fn: 'publishVersionRecordList',
    method: 'get',
    url: '/dashboard/publish-version-record/list',
    hideLoading: true
  },
  {
    fn: 'publishVersionRecordDownloadTemplate',
    method: 'get',
    url: '/dashboard/publish-version-record/downloadTemplate'
  },
  {
    fn: 'publishVersionRecordStop',
    method: 'get',
    url: '/dashboard/publish-version-record/stop',
    direct: true
  },
  {
    fn: 'publishVersionRecordAdd',
    method: 'post',
    url: '/dashboard/publish-version-record/add'
  },
  {
    fn: 'publishVersionRecordGet',
    method: 'get',
    url: '/dashboard/publish-version-record/get',
    direct: true
  },
  {
    fn: 'publishVersionRecordUpdate',
    method: 'post',
    url: '/dashboard/publish-version-record/update'
  },
  {
    fn: 'publishVersionRecordDelete',
    method: 'get',
    url: '/dashboard/publish-version-record/delete',
    direct: true
  }
]

export default publishVersionRecordApis
